import API from '../utils/api';

export default {
  name: 'serviceOperationsApi',
  methods: {
    async submitServiceRequest(body) {
      try {
        const resp = await API.post('/api3/service-requests/add', body);
        return resp;
      } catch (err) {
        return err;
      }
    },
    async getExpertServiceData(param) {
      try {
        const resp = await API.get(
          `/api3/service-requests/all-data?type=${param.type}`,
        );
        return resp;
      } catch (err) {
        return err;
      }
    },
  },
};
