import API2 from '../utils/api';

export default {
  name: 'HelpApi',
  methods: {
    async getHelpData(type, language, category = '') {
      let path = `/help?type=${type}&language=${language}`;
      if (category) path = `${path}&category=${category}`;
      return await new Promise((resolve, reject) => {
        API2.get(path).then(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          },
        );
      });
    },
    async getHelpSections() {
      const path = '/api3/ioc/help_sections?type=tutorial&isSuperAdmin=false';
      return API2.get(path);
    },
  },
};
